<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Komite",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "User",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "User",
          href: "/master/user",
        },
        {
          text: "Edit User",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      username: "",
      nama: "",
      description: "",
      email: "",
      sess: [],
      id: Cookies.get("session_id"),
    };
  },
  mounted() {
    this.username = Cookies.get("session_username");
    this.nama = Cookies.get("session_nama");
    this.sess = Cookies.get("session");
    
    this.getUser();
  },
  methods: {
    getUser() {
      Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
          allowOutsideClick: false,
      });
      let self = this;
      axios
      .get(
      process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/user?id=" +
          this.id
      )
      .then((response) => {
      var response_data = response.data;
      var response_data_fix = response_data.list_data.data[0];
      if (response_data.code == 200) {
          self.email = response_data_fix.email;
          self.description = response_data_fix.desc;
      } else {
          Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response_data.message,
          });
      }
      Swal.close();
      });
    },
    StoreData() {
      let self = this;

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("id", self.id);
      data.append("email", self.email);
      data.append("description", self.description);
      data.append("password", self.password);
      data.append("konfirmasi_password", self.konfirmasi_password);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/user/updateprofil",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data; 
          if(response_data.code != 200){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          }else{
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman profil segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$router.push({ name: "profil" });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
          self.$router.push({ name: "all-user" });
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Username"
                    label-for="formrow-nama-Komite-input"
                  >
                    <b-form-input
                      id="formrow-nama-Komite-input"
                      type="text"
                      v-model="username"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="mb-3"
                    label="Nama"
                    label-for="formrow-nama-Komite-input"
                  >
                    <b-form-input
                      id="formrow-nama-Komite-input"
                      type="text"
                      v-model="nama"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="mb-3"
                    label="Email"
                    label-for="formrow-nama-Komite-input"
                  >
                    <b-form-input
                      id="formrow-nama-Komite-input"
                      type="text"
                      v-model="email"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="mb-3"
                    label="Password"
                  >
                    <b-form-input
                      type="password"
                      v-model="password"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="mb-3"
                    label="Konfirmasi Password"
                  >
                    <b-form-input
                      type="password"
                      v-model="konfirmasi_password"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="mb-3"
                    label="Description"
                    label-for="formrow-nama-Komite-input"
                  >
                    <textarea
                      v-model="description"
                      id=""
                      class="form-control"
                    ></textarea>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <b-button type="submit" variant="primary" class="m-1"
                ><i class="fa fa-save"></i> Simpan</b-button
              >
              <b-button type="reset" variant="danger" class="m-1"
                ><i class="fa fa-refresh"></i> Reset</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
